class Messenger {
  constructor(clients = {}) {
    this.clients = clients;
    this.handlers = [];
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  destroy() {
    window.removeEventListener('message', this.handleMessage.bind(this));
    this.handlers = [];
    this.clients = {};
  }

  addClient(id, target) {
    if (Object.keys(this.clients).includes(id)) {
      //eslint-disable-next-line no-console
      console.warn(`Client with id ${id} already registered.`);
      return false;
    }
    this.clients[id] = target;
    return true;
  }

  removeClient(id) {
    if (!Object.keys(this.clients).includes(id)) {
      //eslint-disable-next-line no-console
      console.warn(`Client with id ${id} is not registered.`);
      return false;
    }
    const client = this.clients[id];
    delete this.clients[id];
    return client;
  }

  send(id, message) {
    const check = !Object.prototype.hasOwnProperty.call(this.clients, id);
    if (check) {
      // eslint-disable-next-line no-console
      console.warn('Client with id "%s" not registered', id);
      return;
    }
    const target = this.clients[id];

    // while loading contentWindow might be `null`
    target.contentWindow?.postMessage(
      {
        ...message,
        payload: {
          ...(message.payload || {}),
          id,
        },
      },
      '*',
    );
  }

  // Accepts an additional dictionary with an widget id to config mapping,
  // that is used as a lookup to populate the payload with additional, widget
  // specific data.
  broadcast(message, extras = {}) {
    Object.keys(this.clients).forEach((id) =>
      // try to look up the actual client’s message at message[clientId],
      // otherwise send complete message.
      this.send(id, {
        ...message,
        payload: {
          ...message.payload,
          extras: (extras && extras[id]) || {},
        },
      }),
    );
  }

  registerHandler(handler) {
    if (this.handlers.includes(handler)) {
      //eslint-disable-next-line no-console
      console.warn('Handler is already registered');
      return false;
    }
    this.handlers.push(handler);
    return true;
  }

  unregisterHandler(handler) {
    if (!this.handlers.includes(handler)) {
      //eslint-disable-next-line no-console
      console.warn('Handler is not registered');
      return false;
    }
    this.handlers.splice(this.handlers.indexOf(handler), 1);
    return true;
  }

  handleMessage(payload) {
    this.handlers.forEach((handler) => handler(payload));
  }
}

export default Messenger;
