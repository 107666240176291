export function toCamelCase(value) {
  return value.replace(/-\w*/g, (w) =>
    w.replace('-', '').replace(/^\w/, (c) => c.toUpperCase()),
  );
}

export function toKebabCase(value) {
  return value.replace(/[A-Z]+/g, (v) => `-${v.toLowerCase()}`);
}

export function codeTypeKey(codeTypeValue) {
  switch (codeTypeValue) {
    case 1:
      return 'ean';
    case 2:
      return 'upc';
    case 3:
      return 'sku';
    default:
      return 'unknown';
  }
}

export class MapWithDefault extends Map {
  constructor(entries, defaultValue) {
    super(entries);
    this.defaultValue = defaultValue;
  }

  get(key, defaultValue) {
    if (this.has(key)) return super.get(key);
    return defaultValue || this.defaultValue;
  }
}
