import * as CONFIG from '../config';
import {
  BACKUP_SIZING_AVAILABLE,
  CHECK_SHOP_AVAILABILITY,
  DEBUG,
  REQUEST,
  SIZE_CONFIRMED,
  SIZE_DOES_NOT_FIT_CONFIRMED,
  SIZE_UNAVAILABLE_CONFIRMED,
  SIZING_AVAILABILITY_CHECK_IN_PROGRESS,
  SIZING_AVAILABLE,
  SIZING_UNAVAILABLE,
  TOGGLE,
  TOGGLE_SIZING_WIDGET,
  WHO_AM_I,
  hideSizingWidget,
  identify,
  showSizingWidget,
  sizeAvailable,
  sizeUnavailable,
  toggleSizingWidget,
} from '../exchange';
import { EVENT_MAPPING } from './events';
import Messenger from './messenger';
import { MapWithDefault, codeTypeKey, toCamelCase, toKebabCase } from './utils';
import { WidgetLoginProvider } from './widgetLoginProvider';

const log = (...args) => {
  if (process.env.NODE_ENV !== 'development') return;
  const messageLog = document.getElementById('message-log');
  if (messageLog) messageLog.append(`${JSON.stringify(args, null, 2)}\n`);
  //eslint-disable-next-line no-console
  else console.log('Debug', args);
};

function castBoolean(v) {
  return v === 'true';
}

function castNoop(v) {
  return v;
}

const TYPE_CASTS = new MapWithDefault([['fullscreen', castBoolean]], castNoop);

// Change this to the widget type that should be loaded, whenever it cannot
// be deducted with certainty.
const FALLBACK_WIDGET = 'default';

function getAvailableWidget(key, config = {}, fallback) {
  const defaultValue = fallback ? fallback : undefined;
  return Object.prototype.hasOwnProperty.call(config, key) ? key : defaultValue;
}

const frameStyleBase = `
  border: none;
`;

const frameStyleHidden = `
  display: none;
`;

const frameStyleLauncher = `
  ${frameStyleBase}
  width: 100%;
  height: 48px;
`;

const frameStyleInline = `
  ${frameStyleBase}
  width: 100%;
  height: 100%;
`;

const frameStyleFullscreen = `
  ${frameStyleBase}
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
`;

class WidgetManager {
  constructor(namespace = 'oz', config = {}) {
    // these should become field declarations:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes#Field_declarations
    this.ids = 0;
    this.widgets = {};
    this.configurations = {
      configs: {},
      widgets: {},
      visibilities: {},
      handlers: {},
    };
    this.globalConfig = config.settings || {};
    this.globalEvents = config.events || {};

    // constructor parameters
    this.namespace = namespace;

    // add object holding event listeners
    this.listeners = {};

    this.widgetLoginProvider = undefined;

    // initialization process
    if (this.globalConfig.noInit === true) return;

    this.initialize();
  }

  initialize() {
    const namespace = this.namespace;

    Object.entries(this.globalEvents || {}).forEach(([event, { callback }]) =>
      this.addEventListener(event, callback),
    );

    if (!this.messenger) this.messenger = new Messenger();
    this.messenger.registerHandler(this.handleMessage.bind(this));

    this.widgetLoginProvider = new WidgetLoginProvider(
      this.messenger,
      this.globalConfig.apiKey,
    );
    this.addEventListener('login', this.widgetLoginProvider.eventListener);

    const depr = [
      ...document.getElementsByClassName(`${namespace}-container`),
      ...document.getElementsByClassName(`${namespace}-trigger`),
    ];
    depr.forEach(this.preprocessDeprecatedConfiguration.bind(this));

    const targets = document.querySelectorAll(`[data-${namespace}-embed]`);
    this.initializeEmbeds(targets);
  }

  preprocessDeprecatedConfiguration(target) {
    const namespace = this.namespace;

    const embed = target.getAttribute(`data-${namespace}-embed`);
    if (embed) return;
    //eslint-disable-next-line no-console
    console.warn('Using deprecated config options');

    const container = target.getAttribute(`data-${namespace}-container`);
    const widget = target.getAttribute(`data-${namespace}-widget`);
    const widgetType = target.getAttribute(`data-${namespace}-widget-type`);
    const isContainer = target.classList.contains(`${namespace}-container`);
    const isTrigger = target.classList.contains(`${namespace}-trigger`);

    const attributes = { widget: widgetType };
    if (widgetType) attributes.widgetType = null;
    if (container) {
      attributes.container = null;
      attributes.target = container;
    }
    if (isTrigger) {
      attributes.embed = 'custom';
      // ignore widget attribute if `widgetType` is undefined and `widget` is
      // launcher (which is implied by `isTrigger`)
      const fallbackWidget = widget !== 'launcher' && widget;
      attributes.widget = attributes.widget || fallbackWidget;
    } else if (isContainer) {
      switch (widget) {
        case 'launcher':
          attributes.widget = widgetType;
          attributes.embed = widget;
          break;
        default:
          attributes.widget = widgetType || widget;
          attributes.embed = 'widget';
          break;
      }
    }
    Object.entries(attributes).forEach(([key, value]) => {
      const attribute = `data-${namespace}-${toKebabCase(key)}`;
      if (!value) target.removeAttribute(attribute);
      else target.setAttribute(attribute, value);
    });
  }

  processConfigiguration(element) {
    const { widgets = [], ...conf } = this.getConfiguration(element);
    this.configurations.configs[conf.id] = conf;

    // FIXME: remove deprecated attribute, here only for backwards compatibility
    // with the current custom loader logic at fahrrad-xxl.de
    element.setAttribute(`${this.namespace}-trigger-id`, conf.id);

    widgets.forEach((widget) => {
      this.configurations.widgets[widget.id] = widget;
    });
  }

  initializeEmbeds(targets) {
    targets.forEach(this.processConfigiguration.bind(this));
    const immediates = Object.values(this.configurations.widgets).filter(
      ({ attributes }) => !attributes.onDemand,
    );
    immediates.forEach(({ id }) => this.addWidget(id));

    function addHandlers(target, handlers = {}) {
      Object.entries(handlers).map((h) => target.addEventListener(...h));
    }
    function removeHandlers(target, handlers = {}) {
      Object.entries(handlers).map((h) => target.removeEventListener(...h));
    }

    const customs = Object.values(this.configurations.configs).filter(
      ({ embed }) => embed === 'custom',
    );
    customs.forEach(({ id, root, idLauncher, idWidget }) => {
      const handlers = this.configurations.handlers[id] || {};
      removeHandlers(root, handlers);

      handlers.click = (event) => {
        event.preventDefault();
        const widgetType = this.configurations.widgets[idWidget].type;
        this.messenger.send(idLauncher, toggleSizingWidget({ widgetType }));
      };
      this.configurations.handlers[id] = handlers;

      function disable({ id: sourceId }) {
        if (id !== sourceId) return;
        root.setAttribute('disabled', 'disabled');
        removeHandlers(root, handlers);
      }
      function enable({ id: sourceId }) {
        if (id !== sourceId) return;
        root.removeAttribute('disabled');
        addHandlers(root, handlers);
      }
      function switchToBackupSizing({ id: sourceId }) {
        if (id !== sourceId) return;
        root.removeAttribute('disabled');
        addHandlers(root, handlers);
      }
      this.addEventListener('sizingAvailabilityCheckInProgress', disable);
      this.addEventListener('sizingUnavailable', disable);
      this.addEventListener('sizingAvailable', enable);
      this.addEventListener('backupSizingAvailable', switchToBackupSizing);
    });
  }

  destroy() {
    const { configs, handlers, widgets } = this.configurations;
    const customs = Object.values(configs).filter(
      ({ embed }) => embed === 'custom',
    );
    customs.forEach(({ id, root }) => {
      const listeners = handlers[id] || {};
      Object.entries(listeners).forEach(([event, handler]) =>
        root.removeEventListener(event, handler),
      );
      root.removeAttribute('disabled');
    });

    Object.keys(widgets).forEach(this.removeWidget.bind(this));
    this.widgets = {};
    this.configurations = {
      configs: {},
      widgets: {},
      visibilities: {},
      handlers: {},
    };
    this.listeners = {};
    this.messenger.destroy();
    this.removeGenericContainer();
  }

  getIdForElement(element, prefix = 'trigger') {
    let id = element.getAttribute('id');
    if (id) return id;

    this.ids = this.ids + 1;
    return `${this.namespace}-${prefix}-${this.ids}`;
  }

  static getAttributes(element, namespace) {
    // returns an object with all data attributes that start with `namespace`
    // e.g. when namespace is _oz_ this element:
    // <div id="test" data-oz-container="container-id" />
    // will return an object in the shape:
    // { container: "container-id" }
    const prefix = `data-${namespace}-`;
    return Array.from(element.attributes).reduce((acc, { name, value }) => {
      if (!name.startsWith(prefix)) return acc;
      const key = toCamelCase(name.replace(prefix, ''));
      acc[key] = TYPE_CASTS.get(key)(value);
      return acc;
    }, {});
  }

  updateConfiguration(config = {}, overrides = {}) {
    const { id, root: element } = config;
    const { attributes: overrideAttributes, ...overrideRest } = overrides;
    const updatedConfig = {
      ...config,
      ...overrideRest,
      attributes: {
        ...this.globalConfig,
        ...config.attributes,
        ...overrideAttributes,
      },
      id,
      root: element,
      widgets: [],
    };

    const {
      idWidget,
      embed = 'launcher',
      attributes: { target: targetAttribute, widgetType: attributedWidgetType },
    } = updatedConfig;
    const widgetType = getAvailableWidget(
      attributedWidgetType,
      CONFIG.WIDGETS,
      FALLBACK_WIDGET,
    );

    const [target, launcherTarget] = this.getContainer(element, {
      target: targetAttribute,
      embed,
    });
    switch (embed) {
      case 'widget':
        updatedConfig.widgets.push({
          config: id,
          id: idWidget,
          type: widgetType,
          attributes: { target: element, onDemand: false },
        });
        break;
      case 'custom':
        updatedConfig.idLauncher = `${id}-launcher`;
        updatedConfig.widgets.push({
          config: id,
          id: `${id}-launcher`,
          type: 'launcher',
          attributes: { target: launcherTarget, onDemand: false, hidden: true },
        });
        updatedConfig.widgets.push({
          config: id,
          id: idWidget,
          type: widgetType,
          attributes: { target, onDemand: true },
        });
        break;
      case 'launcher':
      default:
        updatedConfig.idLauncher = `${id}-launcher`;
        updatedConfig.widgets.push({
          config: id,
          id: `${id}-launcher`,
          type: 'launcher',
          attributes: { target: element, onDemand: false },
        });
        updatedConfig.widgets.push({
          config: id,
          id: idWidget,
          type: widgetType,
          attributes: { target, onDemand: true },
        });
        break;
    }

    return updatedConfig;
  }

  getCallbacksConfigured() {
    const callbacksConfigured = Object.entries(this.listeners)
      .filter(([, callbacks]) => !!callbacks && callbacks.length > 0)
      .map(([event]) => event);
    return callbacksConfigured;
  }

  getConfiguration(element) {
    const id = this.getIdForElement(element);
    element.setAttribute('id', id);

    const callbacksConfigured = this.getCallbacksConfigured();
    const attributes = WidgetManager.getAttributes(element, this.namespace);
    const {
      embed = 'launcher',
      widget,
      target,
      ...restAttributes
    } = attributes;
    const widgetType = getAvailableWidget(
      widget,
      CONFIG.WIDGETS,
      FALLBACK_WIDGET,
    );

    const idWidget = `${id}-widget`;
    const config = this.updateConfiguration({
      id,
      idWidget,
      attributes: {
        ...this.globalConfig,
        ...restAttributes,
        callbacksConfigured,
        target,
        widgetType,
      },
      embed,
      widgets: [],
      root: element,
    });

    return config;
  }

  initTriggers(triggers) {
    //eslint-disable-next-line no-console
    console.warn(
      'initTriggers is deprecated, use updateWidgetConfigurations instead',
    );
    this.updateWidgetConfigurations(triggers);
    this.initializeEmbeds(triggers);
  }

  initContainers(containers) {
    //eslint-disable-next-line no-console
    console.warn(
      'initContainers is deprecated, use updateWidgetConfigurations instead',
    );
    this.updateWidgetConfigurations(containers);
    this.initializeEmbeds(containers);
  }

  getGenericContainer() {
    if (this.genericContainer) return this.genericContainer;
    const id = `${this.namespace}-generic-container`;
    this.genericContainer = document.getElementById(id);
    if (this.genericContainer) return this.genericContainer;

    this.genericContainer = document.createElement('div');
    this.genericContainer.setAttribute('id', id);
    const body = document.querySelector('body');
    body.appendChild(this.genericContainer);
    return this.genericContainer;
  }

  getContainer(element, { target, embed = 'launcher' }) {
    let targetElement;
    switch (embed) {
      case 'widget':
        return [element, null];
      case 'custom':
      case 'launcher':
      default:
        targetElement = target && document.getElementById(target);
    }
    targetElement = targetElement || this.getGenericContainer();

    const hiddenId = `hidden-launchers`;
    let launchersContainer = targetElement.querySelector(`div#${hiddenId}`);
    if (!launchersContainer) {
      launchersContainer = document.createElement('div');
      launchersContainer.setAttribute('id', hiddenId);
      launchersContainer.setAttribute('aria-hidden', true);
      launchersContainer.style.display = 'none';
      targetElement.appendChild(launchersContainer);
    }

    return [targetElement, launchersContainer];
  }

  removeGenericContainer() {
    if (!this.genericContainer) return;
    const parent = this.genericContainer.parentElement;
    if (parent) parent.removeChild(this.genericContainer);
    this.genericContainer = undefined;
  }

  toggleWidget(id) {
    const visible = !!this.configurations.visibilities[id];
    if (visible) {
      this.removeWidget(id);
      return false;
    } else {
      this.addWidget(id);
      return true;
    }
  }

  getWidget(id, src, config = {}) {
    if (this.widgets[id]) return this.widgets[id];
    const {
      embed,
      idLauncher,
      idWidget,
      attributes: { fullscreen } = {},
    } = config;

    const widget = document.createElement('iframe');
    widget.setAttribute('id', id);
    widget.setAttribute('src', src);

    const widgetStyle = fullscreen ? frameStyleFullscreen : frameStyleInline;

    switch (embed) {
      case 'widget':
        if (id === idWidget) widget.style = widgetStyle;
        else if (id === idLauncher) widget.style = frameStyleLauncher;
        break;
      case 'custom':
        if (id === idWidget) widget.style = widgetStyle;
        else if (id === idLauncher) widget.style = frameStyleHidden;
        break;
      case 'launcher':
        if (id === idWidget)
          widget.style =
            fullscreen === undefined ? frameStyleFullscreen : widgetStyle;
        else if (id === idLauncher) widget.style = frameStyleLauncher;
        break;
    }

    this.widgets[id] = widget;
    return widget;
  }

  addWidget(id) {
    const { config, type, attributes } = this.configurations.widgets[id];

    const src = CONFIG.WIDGETS[type];

    const configuration = this.configurations.configs[config];
    const container = attributes.target;
    const widget = this.getWidget(id, src, configuration);
    this.configurations.visibilities[id] = true;
    if (container.contains(widget)) return widget;

    this.appendWidget(container, widget);

    this.messenger.addClient(id, widget);
    return widget;
  }

  appendWidget(target, widget) {
    target.appendChild(widget);

    // this should be handled by dedicated example-code
    // eslint-disable-next-line no-unused-vars
    const id = Object.entries(this.widgets).find(([_, w]) => w === widget)[0];
    const option = document.createElement('option');
    option.setAttribute('value', id);
    option.innerHTML = id;
    const select = document.querySelector('#message-target');
    if (select) select.appendChild(option);
  }

  removeWidget(id) {
    const { config, type, attributes } = this.configurations.widgets[id];

    const src = CONFIG.WIDGETS[type];
    const configuration = this.configurations.configs[config];
    const container = attributes.target;
    const widget = this.getWidget(id, src, configuration);
    if (container.contains(widget)) container.removeChild(widget);

    this.configurations.visibilities[id] = false;
  }

  prepareWidgetConfigurationsForMessenger() {
    return Object.entries(this.configurations.widgets).reduce(
      (acc, [key, { config, attributes = {} }]) => ({
        ...acc,
        [key]: {
          ...attributes,
          ...this.configurations.configs[config].attributes,
        },
      }),
      {},
    );
  }

  broadcastWidgetConfigurations() {
    const widgetConfigurations = this.prepareWidgetConfigurationsForMessenger();
    this.messenger.broadcast(identify(''), widgetConfigurations);
  }

  updateWidgetConfigurations(targets) {
    targets.forEach(this.preprocessDeprecatedConfiguration.bind(this));
    targets.forEach(this.processConfigiguration.bind(this));
    this.broadcastWidgetConfigurations();
  }

  handleMessage(event) {
    log('host received message', event);

    const { type, payload } = event.data;

    const sourceId = payload?.id;
    let idLauncher, idWidget, configId, widgetType, sender, source, config;
    if (payload?.id) {
      // wrangle data so that all payloads include all IDs
      const widgetInfo = this.configurations.widgets[sourceId];
      configId = widgetInfo.config;
      sender = widgetInfo.type;
      source = widgetInfo.attributes.target;

      config = this.configurations.configs[configId];
      widgetType = config.attributes.widgetType;
      if (widgetInfo.type === 'launcher') {
        idLauncher = sourceId;
        idWidget = config.idWidget;
      } else {
        idWidget = sourceId;
        idLauncher = config.idLauncher;
      }
    }

    const namespace = this.namespace;
    // trigger event on registered listeners
    this.dispatch(type, {
      ...payload,
      id: configId,
      // augment payload with additional information
      [`${namespace}-meta`]: {
        id: configId,
        idWidget,
        idLauncher,
        widgetType, // holds the widget type that will be launched by this event
        sender, // holds the widget type that triggered this event
        source, // holds the html element that triggered this event
        sourceId,
      },
    });

    function updateConfigWithPayload(config = {}, payload = {}) {
      if (config && payload?.widgetType) {
        const { widgets = [], ...conf } = this.updateConfiguration(config, {
          attributes: { widgetType: payload.widgetType },
        });
        this.configurations.configs[conf.id] = conf;
        widgets.forEach((widget) => {
          this.configurations.widgets[widget.id] = widget;
        });
      }
    }

    let isVisible, message;
    switch (type) {
      case TOGGLE:
      case TOGGLE_SIZING_WIDGET:
        updateConfigWithPayload.bind(this)(config, payload);
      // eslint-disable-next-line: no-fallthrough
      case SIZE_CONFIRMED:
      case SIZE_UNAVAILABLE_CONFIRMED:
      case SIZE_DOES_NOT_FIT_CONFIRMED:
        isVisible = this.toggleWidget(idWidget);
        message = isVisible ? showSizingWidget : hideSizingWidget;
        this.messenger.send(idLauncher, message(idWidget));
        break;
      case WHO_AM_I:
        log(event.data);
        this.broadcastWidgetConfigurations();
        break;
      case REQUEST:
        log(event.data);
        this.handleTransmissionRequest(event.data.payload);
        break;
      case DEBUG:
        log(event.data);
        break;
      case SIZING_AVAILABLE:
      case SIZING_UNAVAILABLE:
      case BACKUP_SIZING_AVAILABLE:
        updateConfigWithPayload.bind(this)(config, payload);
      // eslint-disable-next-line: no-fallthrough
      case SIZING_AVAILABILITY_CHECK_IN_PROGRESS:
        log(event.data);
        break;
      default:
        break;
    }
  }

  async handleTransmissionRequest(request) {
    const { id, action, code, size, codeType } = request;
    if (!id || !Object.keys(EVENT_MAPPING).includes(action)) return;

    const results = this.dispatch(action, {
      code,
      size,
      displaySize01: size,
      codeType: codeTypeKey(codeType),
    });

    let message = null;
    // build responses to be dispatched in widgets
    if (results.length && action === CHECK_SHOP_AVAILABILITY) {
      if (results[0]) {
        try {
          const value = await Promise.resolve(results[0]);
          message = value ? sizeAvailable() : sizeUnavailable();
        } catch (error) {
          message = sizeUnavailable();
        }
      } else message = sizeUnavailable();
    }
    if (message) this.messenger.send(id, message);
  }

  dispatch(action, payload) {
    const event = EVENT_MAPPING[action];
    if (!event) return false;
    const callbacks = this.listeners[event] || [];
    return callbacks.map((cb) => cb && typeof cb === 'function' && cb(payload));
  }

  addEventListener(event, callback) {
    if (!Object.keys(this.listeners).includes(event)) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
    if (this.messenger) {
      this.updateWidgetConfigurations(
        Object.values(this.configurations.configs).map(({ root }) => root),
      );
    }
  }

  removeEventListener(event, callback) {
    if (!Object.keys(this.listeners).includes(event)) return;
    this.listeners[event] = this.listeners[event].filter(
      (cb) => cb !== callback,
    );
    if (this.messenger) {
      this.updateWidgetConfigurations(
        Object.values(this.configurations.configs).map(({ root }) => root),
      );
    }
  }
}

export default WidgetManager;
