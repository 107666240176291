const VERSION = process.env.VERSION;
const API_URL = process.env.API_URL;
const API_VERSION = process.env.API_VERSION || 'v16';
const AUTH_RETRIES = 3;
const DEFAULT_NAMESPACE = 'oz';

const INTERCEPT_CONFIG_KEY = `${DEFAULT_NAMESPACE}-interceptions`;
const INTERCEPT_DEFAULT_CONFIG = {
  intercept: false,
  shouldAuthenticate: true,
  shouldRefreshAuthentication: false,
  shouldInterceptAuthentication: false,
  shouldInterceptAlternatives: false,
  shouldInterceptAverages: false,
  shouldInterceptSizing: false,
  shouldInterceptBikeInformation: false,
  shouldInterceptWidgets: false,
  shouldTimeout: false,
};

// cant be used from loader!
const REFERER = process.env.REFERER
  ? process.env.REFERER
  : document.referrer?.split('/')[2]?.split(':')[0] || null;

const DEFAULT_WIDGET_URL = process.env.DEFAULT_WIDGET_URL || '/alternatives';
const ALTERNATIVES_WIDGET_URL =
  process.env.ALTERNATIVES_WIDGET_URL || '/alternatives';
const SIZING_WIDGET_URL = process.env.SIZING_WIDGET_URL || '/sizing';
const KIDS_WIDGET_URL = process.env.KIDS_WIDGET_URL || '/kids';
const KIDS_ALTERNATIVES_WIDGET_URL =
  process.env.KIDS_ALTERNATIVES_WIDGET_URL || '/kidsAlternatives';
const GEOMETRIES_WIDGET_URL =
  process.env.GEOMETRIES_WIDGET_URL || '/geometries';
const LAUNCHER_WIDGET_URL = process.env.LAUNCHER_WIDGET_URL || '/launcher';
const SKI_WIDGET_URL = process.env.SKI_WIDGET_URL || '/ski';
const BACKUP_SIZING_WIDGET_URL =
  process.env.BACKUP_SIZING_WIDGET_URL || '/backupSizing';

const WIDGETS = {
  default: DEFAULT_WIDGET_URL,
  sizing: SIZING_WIDGET_URL,
  alternatives: ALTERNATIVES_WIDGET_URL,
  kids: KIDS_WIDGET_URL,
  kidsAlternatives: KIDS_ALTERNATIVES_WIDGET_URL,
  geometries: GEOMETRIES_WIDGET_URL,
  launcher: LAUNCHER_WIDGET_URL,
  ski: SKI_WIDGET_URL,
  backupSizing: BACKUP_SIZING_WIDGET_URL,
};

export {
  API_URL,
  API_VERSION,
  AUTH_RETRIES,
  DEFAULT_NAMESPACE,
  INTERCEPT_CONFIG_KEY,
  INTERCEPT_DEFAULT_CONFIG,
  REFERER,
  VERSION,
  WIDGETS,
};
