import { AUTH_RETRIES } from '../config';
import { LOGIN } from '../exchange';
import { Auth } from './auth';

export class WidgetLoginProvider {
  #attempts = 1;
  #loginQueue = [];
  #authenticator = new Auth();
  #messenger;
  #loginHeaders;

  constructor(messenger, apiKey) {
    this.#messenger = messenger;
    this.#loginHeaders = {
      'X-Api-Key': `Api-Key ${apiKey}`,
    };
  }

  get eventListener() {
    return this.processLogin.bind(this);
  }

  async processLogin(data) {
    try {
      const { referrer, jwtToken } = data;
      const { sourceId } = data['oz-meta'];
      // maybe the best way to prevent multi async actions in js o.O
      const queueLength = this.#loginQueue.push(sourceId);
      if (queueLength !== 1) return;
      this.#processQueue(await this.#queryLogin(referrer, jwtToken));
    } catch (error) {
      this.#processQueue({
        error: error.message || 'Internal login error',
      });
    }
  }

  async #queryLogin(referrer, currentJwtToken) {
    try {
      const loginData = await this.#authenticator.login(
        {
          headers: this.#loginHeaders,
          body: { referrer },
        },
        currentJwtToken,
      );
      this.#reset();

      return loginData;
    } catch (error) {
      if (await this.#isReadyToRetry()) return this.#queryLogin(referrer);
      this.#reset();
      return {
        error: error.message || 'Internal login error',
      };
    }
  }

  #reset() {
    this.#attempts = 1;
  }

  #processQueue(loginData) {
    const processedIds = new Set();
    let widgetId = this.#loginQueue[0];
    /*
     * we dont want to give any widget time to get index 0 before the queue is fully processed
     * maybe there's still a tiny chance :(
     * worst case should give a double response
     */
    do {
      if (!processedIds.has(widgetId)) {
        this.#sendResponse(widgetId, loginData);
      }
      processedIds.add(widgetId);
      this.#loginQueue.shift();
    } while ((widgetId = this.#loginQueue[0]) != null);
  }

  #sendResponse(widgetId, loginData) {
    this.#messenger.send(widgetId, {
      type: LOGIN,
      payload: { loginData },
    });
  }

  async #isReadyToRetry() {
    if (this.#attempts < AUTH_RETRIES) {
      const timeout = Math.min(1000 * 2 ** this.#attempts, 30000);
      this.#attempts += 1;
      await new Promise((resolve) => setTimeout(resolve, timeout));
      return true;
    }
    return false;
  }
}
