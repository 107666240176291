export class JWT {
  static decode(token) {
    const parts = token.split('.');
    if (parts.length != 3) throw new Error('Not an jwt');
    const tokenB64 = window.atob(parts[1]);
    return JSON.parse(tokenB64.toString());
  }

  static tryDecode(token) {
    if (!token) return undefined;
    try {
      return JWT.decode(token);
    } catch {
      return undefined;
    }
  }

  static isExpired(token) {
    if (typeof token === 'string') token = JWT.tryDecode(token);
    if (token == null || !('exp' in token)) return true;
    const { exp = 0 } = token;
    return exp * 1000 < Date.now() - 10_000;
  }

  static storedToken(referrer) {
    const stateItem = `oz-${referrer}-state`;
    const state = JSON.parse(localStorage.getItem(stateItem));
    return state?.auth?.token ?? null;
  }

  static storeToken(referrer, token) {
    const stateItem = `oz-${referrer}-state`;
    const state = JSON.parse(localStorage.getItem(stateItem));
    const newState = {
      ...state,
      auth: {
        token,
      },
    };
    localStorage.setItem(stateItem, JSON.stringify(newState));
  }
}
