import { JWT } from '../applications/utils/jwt';
import { API_URL, API_VERSION } from '../config';

export class Auth {
  static TokenExpiryOffsetMilliseconds = 10000;
  static LoginPath = '/login';
  static ApiUrl = `${API_URL}${API_VERSION}${this.LoginPath}`;
  static #jwtToken;

  constructor() {
    Auth.#jwtToken = null;
  }

  async login(credentials, currentJwtToken) {
    if (Auth.#jwtToken == null) Auth.#jwtToken = currentJwtToken;
    if (!JWT.isExpired(Auth.#jwtToken)) {
      await new Promise((res) => {
        setTimeout(res, 0);
      });
      return this.loginData(Auth.#jwtToken);
    }

    const body = JSON.stringify(credentials.body);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Session-Id': null,
    });
    Object.entries(credentials.headers).forEach(([key, value]) =>
      headers.append(key, value),
    );
    const response = await fetch(Auth.ApiUrl, {
      method: 'POST',
      headers,
      body,
    });
    if (response.status > 299) {
      const responseBody = await this.tryGetBody(response);
      throw new Error(
        response.statusText || responseBody?.message || response.status,
      );
    }

    const responseHeaders = response.headers;
    if (responseHeaders.has('jwt')) {
      Auth.#jwtToken = responseHeaders.get('jwt');
      return this.loginData(Auth.#jwtToken);
    }
    throw new Error('No jwt in response header');
  }

  async tryGetBody(respose) {
    try {
      return respose.json();
    } catch {
      return null;
    }
  }

  loginData(token) {
    return {
      response: { status: 'OK' },
      token,
    };
  }
}
