import {
  BACKUP_SIZING_AVAILABLE,
  CHECK_SHOP_AVAILABILITY,
  LOGIN,
  SIZE_CONFIRMED,
  SIZE_DOES_NOT_FIT_CONFIRMED,
  SIZE_UNAVAILABLE_CONFIRMED,
  SIZING_AVAILABILITY_CHECK_IN_PROGRESS,
  SIZING_AVAILABLE,
  SIZING_UNAVAILABLE,
  USAGE_INSIGHTS,
} from '../exchange/messages';

// a mapping of message types to events that are dispatched by the loader
// e.g. when a message of type `AVAILABLE` is received, loader will call
// all registered callbacks for event `available`
export const EVENT_MAPPING = {
  [SIZE_CONFIRMED]: 'confirmSize',
  [SIZING_AVAILABLE]: 'sizingAvailable',
  [SIZING_UNAVAILABLE]: 'sizingUnavailable',
  [SIZING_AVAILABILITY_CHECK_IN_PROGRESS]: 'sizingAvailabilityCheckInProgress',
  [BACKUP_SIZING_AVAILABLE]: 'backupSizingAvailable',
  [CHECK_SHOP_AVAILABILITY]: 'checkIsAvailable',
  [SIZE_DOES_NOT_FIT_CONFIRMED]: 'confirmSizeDoesNotFit',
  [SIZE_UNAVAILABLE_CONFIRMED]: 'confirmSizeUnavailable',
  [USAGE_INSIGHTS]: 'usageInsights',
  [LOGIN]: 'login',
};
