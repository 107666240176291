import * as CONFIG from '../config';
import WidgetManager from './WidgetManager';

const initWidgets = (win) => {
  const namespace = win['___OnlineSizing'] || CONFIG.DEFAULT_NAMESPACE;
  const config = win['___OnlineSizingConfig'] || {};
  const manager = new WidgetManager(namespace, config);

  win[namespace] = manager;

  const loaded = new CustomEvent(`${namespace}Loaded`, { detail: { manager } });

  document.dispatchEvent(loaded);
};

initWidgets(window);

if (process.env.NODE_ENV === 'development') {
  //eslint-disable-next-line no-console
  console.log('Activate hot reloading');
  module.hot.accept();
}
