// Message creators used during host-widget communication

import { REFERER } from '../config';

export const DEBUG = 'oz/DEBUG';
export const WHO_AM_I = 'oz/WHO_AM_I';

// send id and configuration to widget
export const IDENTIFY = 'oz/IDENTIFY';

export const TOGGLE = 'oz/TOGGLE';
// open a sizing widget using the config of a widget indicated by `payload.id`
export const HIDE_SIZING_WIDGET = 'oz/HIDE_SIZING_WIDGET';
export const SHOW_SIZING_WIDGET = 'oz/SHOW_SIZING_WIDGET';
export const TOGGLE_SIZING_WIDGET = 'oz/TOGGLE_SIZING_WIDGET';

export const SIZING_AVAILABLE = 'oz/SIZING_AVAILABLE';
export const SIZING_UNAVAILABLE = 'oz/SIZING_UNAVAILABLE';
export const SIZING_AVAILABILITY_CHECK_IN_PROGRESS =
  'oz/SIZING_AVAILABILITY_CHECK_IN_PROGRESS';
export const SUCCESS = 'oz/SUCCESS';
export const BACKUP_SIZING_AVAILABLE = 'oz/BACKUP_SIZING_AVAILABLE';

// size selection confirmed in widget
export const SIZE_CONFIRMED = 'oz/SIZE_CONFIRMED';

// size does not fit confirmed
export const SIZE_DOES_NOT_FIT_CONFIRMED = 'oz/SIZE_DOES_NOT_FIT_CONFIRMED';

// size unavailable confirmed
export const SIZE_UNAVAILABLE_CONFIRMED = 'oz/SIZE_UNAVAILABLE_CONFIRMED';

// shop replies with size availability
export const SIZE_AVAILABLE = 'shop/SIZE_AVAILABLE';
export const SIZE_UNAVAILABLE = 'shop/SIZE_UNAVAILABLE';

// widget sends a request with an one of the constants below specified as
// payload and expects a corresponding answer, e.g. `CHECK_SHOP_AVAILABILITY`
// is expected to either yield `SIZE_AVAILABLE` or `SIZE_UNAVAILABLE`
export const REQUEST = 'oz/REQUEST';
// these types are only mapped to listeners / callbacks, thus have no action
// creators. Will be used as constants in request’s action parameter
export const CHECK_SHOP_AVAILABILITY = 'oz/CHECK_SHOP_AVAILABILITY';

// TOGGLE UNIT
export const TOGGLE_UNITS = 'oz/TOGGLE_UNITS';

// Tracking callback
export const USAGE_INSIGHTS = 'oz/USAGE_INSIGHTS';

export const LOGIN = 'oz/LOGIN';

export const debug = (msg) => ({ type: DEBUG, payload: { msg } });
export const whoAmI = () => ({ type: WHO_AM_I });
export const identify = (id) => ({ type: IDENTIFY, payload: { id } });
export const toggle = (id) => ({ type: TOGGLE, payload: { id } });
export const hideSizingWidget = (id) => ({
  type: HIDE_SIZING_WIDGET,
  payload: { id },
});
export const showSizingWidget = (id) => ({
  type: SHOW_SIZING_WIDGET,
  payload: { id },
});
export const toggleSizingWidget = (payload) => ({
  type: TOGGLE_SIZING_WIDGET,
  payload,
});
export const sizingAvailable = (payload) => ({
  type: SIZING_AVAILABLE,
  payload,
});
export const sizingUnavailable = (payload) => ({
  type: SIZING_UNAVAILABLE,
  payload,
});
export const sizingAvailabilityCheckInProgress = (payload) => ({
  type: SIZING_AVAILABILITY_CHECK_IN_PROGRESS,
  payload,
});
export const backupSizingAvailable = (payload) => ({
  type: BACKUP_SIZING_AVAILABLE,
  payload,
});

export const request = (action) => ({
  type: REQUEST,
  payload: { ...action },
});
export const confirmSize = (payload) => ({ type: SIZE_CONFIRMED, payload });
export const confirmSizeDoesNotFit = (payload) => ({
  type: SIZE_DOES_NOT_FIT_CONFIRMED,
  payload,
});
export const confirmSizeUnavailable = (payload) => ({
  type: SIZE_UNAVAILABLE_CONFIRMED,
  payload,
});

export const sizeAvailable = () => ({ type: SIZE_AVAILABLE });
export const sizeUnavailable = () => ({ type: SIZE_UNAVAILABLE });

export const usageInsights = (payload) => ({ type: USAGE_INSIGHTS, payload });

export const login = (jwtToken) => ({
  type: LOGIN,
  payload: { referrer: REFERER, jwtToken },
});
